import {
	AppBar,
	Box,
	Container,
	IconButton,
	Link,
	Menu,
	MenuItem,
	Toolbar,
	Typography,
} from "@mui/material";

import { LogoWithWhiteBrandName, MenuIcon } from "assets";
import { APP_ENDPOINTS } from "constants/AppEndpoints";
import { NAV_LINKS } from "constants/Navlinks";
import { useState } from "react";

function Navbar() {
	const [anchorElNav, setAnchorElNav] = useState(null);

	const handleOpenNavMenu = (event) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleCloseNavMenu = () => {
		setAnchorElNav(null);
	};

	return (
		<AppBar
			position='fixed'
			color='transparent'
			elevation={0}
			sx={{ backdropFilter: "blur(10px)" }}>
			<Container maxWidth='xl'>
				<Toolbar
					component={Box}
					display='flex'
					justifyContent='space-between'
					my={1.2}>
					<Box
						component='a'
						href={`#${APP_ENDPOINTS.HOME}`}
						sx={{
							// mr: 2,
							display: { xs: "none", md: "flex" },
						}}>
						<img
							src={LogoWithWhiteBrandName}
							alt='Home'
							width={64}
							height={64}
						/>
					</Box>

					<Box
						component='a'
						href={`#${APP_ENDPOINTS.HOME}`}
						sx={{
							// mr: 2,
							display: { xs: "flex", md: "none" },
							// flexGrow: 1,
						}}>
						<img
							src={LogoWithWhiteBrandName}
							alt='Home'
							width={64}
							height={64}
						/>
					</Box>

					<Box sx={{ display: { xs: "flex", md: "none" } }}>
						<IconButton
							size='large'
							aria-label='hamburger icon'
							aria-controls='menu-appbar'
							aria-haspopup='true'
							onClick={handleOpenNavMenu}
							color='inherit'>
							<MenuIcon />
						</IconButton>
						<Menu
							id='menu-appbar'
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: "bottom",
								horizontal: "left",
							}}
							keepMounted
							transformOrigin={{
								vertical: "top",
								horizontal: "left",
							}}
							open={Boolean(anchorElNav)}
							onClose={handleCloseNavMenu}
							sx={{
								display: { xs: "block", md: "none" },
							}}>
							{NAV_LINKS.map((nav) => (
								<MenuItem
									key={nav.id}
									component={Link}
									href={`#${nav.path}`}
									onClick={handleCloseNavMenu}>
									<Typography textAlign='center' color={"GrayText"}>
										{nav.title}
									</Typography>
								</MenuItem>
							))}
						</Menu>
					</Box>

					<Box sx={{ display: { xs: "none", md: "flex" } }}>
						{NAV_LINKS.map((nav) => (
							<Box
								key={nav.id}
								component={Link}
								underline='none'
								href={`#${nav.path}`}
								onClick={handleCloseNavMenu}
								sx={{ m: 2, px: 2, display: "block" }}>
								<Typography variant='h6' color='white'>
									{nav.title}
								</Typography>
							</Box>
						))}
					</Box>
				</Toolbar>
			</Container>
		</AppBar>
	);
}
export default Navbar;
