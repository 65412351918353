import { Container } from "@mui/material";
import Navbar from "components/Navbar";
import Main from "pages/Main";

function App() {
	return (
		<>
			<Navbar />
			<Main />
		</>
	);
}

export default App;

