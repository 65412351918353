import { Box, Container } from "@mui/material";
import {
	BackgroundImageFive,
	BackgroundImageFour,
	BackgroundImageOne,
	BackgroundImageSix,
	BackgroundImageThree,
	BackgroundImageTwo,
} from "assets";
import AboutUsSection from "components/AboutUsSection";
import ContactUsSection from "components/ContactUsSection";
import ExpertiseSection from "components/ExpertiseSection";
import FreelancersSection from "components/FreelancersSection";
import HeroSection from "components/HeroSection";
import ServicesSection from "components/ServicesSection";
import ServicesSectionPageTwo from "components/ServicesSectionPageTwo";
import { APP_ENDPOINTS } from "constants/AppEndpoints";

const Main = () => {
	const SectionBox = ({ children, image, id }) => (
		<Box
			component='section'
			sx={{
				width: "100vw",
				height: "100vh",
				backgroundImage: `url(${image})`,
			}}
			id={id}>
			{children}
		</Box>
	);

	return (
		<>
			<SectionBox id={APP_ENDPOINTS.HOME} image={BackgroundImageOne}>
				<Container maxWidth='xl' sx={{ height: "100%" }}>
					<HeroSection />
				</Container>
			</SectionBox>

			<SectionBox id={APP_ENDPOINTS.ABOUT} image={BackgroundImageTwo}>
				<Container maxWidth='xl' sx={{ height: "100%" }}>
					<AboutUsSection />
				</Container>
			</SectionBox>

			<SectionBox id={APP_ENDPOINTS.SERVICES} image={BackgroundImageThree}>
				<Container maxWidth='xl' sx={{ height: "100%" }}>
					<ServicesSection />
				</Container>
			</SectionBox>

			<SectionBox
				id={APP_ENDPOINTS.SERVICES_PAGE_TWO}
				image={BackgroundImageFour}>
				<Container maxWidth='xl' sx={{ height: "100%" }}>
					<ServicesSectionPageTwo />
				</Container>
			</SectionBox>

			<SectionBox id={APP_ENDPOINTS.EXPERTISE} image={BackgroundImageFive}>
				<Container maxWidth='xl' sx={{ height: "100%" }}>
					<ExpertiseSection />
				</Container>
			</SectionBox>

			<SectionBox id={APP_ENDPOINTS.FREELANCERS} image={BackgroundImageSix}>
				<Container maxWidth='xl' sx={{ height: "100%" }}>
					<FreelancersSection />
				</Container>
			</SectionBox>

			<SectionBox id={APP_ENDPOINTS.CONTACT_US} image={BackgroundImageOne}>
				<Container maxWidth='xl' sx={{ height: "100%" }}>
					<ContactUsSection />
				</Container>
			</SectionBox>
		</>
	);
};

export default Main;
