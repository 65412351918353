import { Grid, Typography } from "@mui/material";
import { BackgroundImageFour } from "assets";

const HeroSection = () => {
	return (
		<Grid
			container
			alignItems='center'
			sx={{ height: "100%" }}
			justifyContent='space-between'>
			<Grid item xs={12} md={5} sx={{ marginTop: 12 }}>
				<img src={BackgroundImageFour} width='100%' height='100%' alt='hero' />
			</Grid>
			<Grid item xs={12} md={5}>
				<Typography
					sx={{
						fontSize: { xs: 40, md: 60 },
						fontWeight: 700,
						letterSpacing: "2px",
					}}
					color='white'>
					Where <br />
					Recruitment
					<br /> meets <br />
					Technology and Analytics
				</Typography>
			</Grid>
		</Grid>
	);
};

export default HeroSection;
