import { Box, Typography } from "@mui/material";

const AboutUsSection = () => {
	return (
		<Box display={"flex"} alignItems={"center"} sx={{ height: "100%" }}>
			<Typography
				sx={{
					pl: { xs: 1, md: 10 },
					width: { xs: "100%", md: "50%" },
					fontSize: { xs: "1.5rem", md: "2.5rem" },
					textShadow: "2px 2px #211e19",
				}}
				color='white'>
				<strong>Rekrutech</strong> is a service company that is expert in
				providing HR based services to its clients. We deliver best result using
				our Technology and Analytics. We have dedicated experts providing
				recruitment solutions to domains like IT, FMCG, Retail , Logistics,
				BFSI, Telecom etc. Our Search capability will provide best candidates ,
				Technology and Analytics will help provide optimised solutions to our
				clients
			</Typography>
		</Box>
	);
};

export default AboutUsSection;
