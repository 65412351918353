import { Grid, Typography } from "@mui/material";

const ExpertiseSection = () => {
	const BulletPoint = ({ children }) => (
		<Typography
			color='white'
			sx={{
				fontSize: { xs: "1.5rem", md: "2.5rem", textShadow: "1px 1px grey" },
			}}>
			• {children}
		</Typography>
	);

	return (
		// <Box sx={{ height: "100%" }} display='flex' alignItems='center'>
		<Grid container sx={{ pt: 10 }}>
			<Grid item xs={12}>
				<BulletPoint>
					We have expert recruiters working full time and part time to provide
					best match for the client's requirement
				</BulletPoint>
			</Grid>
			<Grid item xs={12}>
				<BulletPoint>
					We use Analytics to get the insights of the market while recruitment
					process to optimise it
				</BulletPoint>
			</Grid>
			<Grid item xs={12}>
				<BulletPoint>
					We are building our own ATS which will help us finding the best
					skillset
				</BulletPoint>
			</Grid>
			<Grid item xs={12}>
				<BulletPoint>
					We have worked on multiple domains across industry like BFSI,
					Manufacturing, IT etc
				</BulletPoint>
			</Grid>
		</Grid>
		// </Box>
	);
};

export default ExpertiseSection;
