import { Box, Grid, Typography } from "@mui/material";
import { DialerIcon, MailIcon } from "assets";

const ContactUsSection = () => {
	return (
		<Box pt={12}>
			<Grid container item xs={12}>
				<Typography variant='h4' color='white'>
					Connect with us:
				</Typography>
			</Grid>

			<Grid container item xs={12} alignItems='center'>
				<Typography
					color='white'
					sx={{
						display: "flex",
						alignItems: "center",
						fontSize: { xs: "1rem", md: "2.5rem" },
					}}>
					<MailIcon /> &nbsp; &nbsp; rampraveshrekrutech@gmail.com
				</Typography>
			</Grid>
			<Grid container item xs={12} alignItems='center'>
				<Typography
					color='white'
					sx={{
						display: "flex",
						alignItems: "center",
						fontSize: { xs: "1rem", md: "2.5rem" },
					}}>
					<DialerIcon /> &nbsp; &nbsp; +91 7978571862
				</Typography>
			</Grid>
		</Box>
	);
};

export default ContactUsSection;
