import { Box, Typography } from "@mui/material";

const ServicesSectionPageTwo = () => {
	return (
		<Box display='flex' alignItems='center' sx={{ height: "100%" }}>
			<Typography
				color='white'
				sx={{
					fontSize: { xs: "1.5rem", md: "3rem" },
					textShadow: "2px 1px grey",
				}}>
				ATS (COMING SOON ...)
			</Typography>
		</Box>
	);
};

export default ServicesSectionPageTwo;
