import { Box, Button, Link, Typography } from "@mui/material";

const FreelancersSection = () => {
	const BulletPoint = ({ children }) => (
		<Typography
			gutterBottom
			color='white'
			sx={{ fontSize: { xs: "1rem", md: "2rem" }, width: { xs: "100%" } }}
			component='li'>
			{children}
		</Typography>
	);
	return (
		<Box
			display='flex'
			alignItems='center'
			justifyContent='center'
			sx={{ height: "90%" }}>
			<Box sx={{ width: { xs: "100%", md: "45vw" } }}>
				<BulletPoint>High Pay-out on candidates getting hired</BulletPoint>
				<BulletPoint>
					No Business Development cost - Access to multiple clients across all
					levels
				</BulletPoint>
				<BulletPoint>
					Access to ATS - To get real time feedback and access to screen the
					profiles with Technology
				</BulletPoint>
				<BulletPoint>
					Access to Client Requisition insights and performance from our
					Analytics team
				</BulletPoint>

				<Button
					component={Link}
					underline='none'
					href='mailto:rampraveshrekrutech@gmail.com'
					variant='outlined'>
					Freelancers and Recruiters - Click here to share your CV
				</Button>
			</Box>
		</Box>
	);
};

export default FreelancersSection;
