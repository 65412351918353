import { Box, Typography } from "@mui/material";

const ServicesSection = () => {
	return (
		<Box
			display='flex'
			alignItems='center'
			justifyContent='flex-end'
			sx={{ height: "100%" }}>
			<Box
				sx={{
					pl: 4,
					width: { xs: "100%", md: "30%" },
				}}>
				<ul>
					<Typography
						color='white'
						component='li'
						gutterBottom
						sx={{ fontSize: { xs: "1.5rem", md: "2.5rem" } }}>
						Multi Level Hiring
					</Typography>
				</ul>
				<Typography
					color='white'
					sx={{ fontSize: { xs: "1rem", md: "2rem" } }}
					gutterBottom>
					We help MNCs and Indian clients across multiple segments recruit at
					all levels
				</Typography>
				<ul>
					<Typography
						color='white'
						component='li'
						gutterBottom
						sx={{ fontSize: { xs: "1.5rem", md: "2.5rem" } }}>
						Market Research
					</Typography>
				</ul>
				<Typography color='white' sx={{ fontSize: { xs: "1rem", md: "2rem" } }}>
					We provide competitor analysis and various HR related research with
					market insights
				</Typography>
			</Box>
		</Box>
	);
};

export default ServicesSection;
